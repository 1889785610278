.addRelationalBackground {
    height: 100vh;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: url("../images/Group2.png") !important;
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
}

.ProfileLeftColumn {
    background: transparent;
    backdrop-filter: blur(10px);
    box-shadow: 0px 6px 60px #2b2e2a;
    height: 55vh;
    width: 80%;
    position: sticky;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    color: #fff;
    margin-top: 160px !important;
    margin: auto;
    border-radius: 20px;
}

.aboutdetails h2 {
    font-size: 30px;
    color: #ffffff;
    font-weight: 800;
}

.aboutdetails {
    max-width: 300px;
    margin-top: 20px;
}

.detailphoto {
    display: flex;
    justify-content: space-between;
    width: 80%;
}

.aboutp img {
    height: 450px;
    margin-top: -60px;
}

.aboutfooter {
    height: 50px;
    width: 100%;
    background: #045a14;
    padding-top: 10px;
    position: fixed;
    bottom: 0;
    color: #ffffff;
    display: flex;
    justify-content: space-between;
}
.pol{
    margin-right: 30px;
}
.pol a {
    color: #ffffff;
    margin-right: 30px;
}
.powerby{
    margin-left: 10px;
}
.powerby a{
    font-weight: 700;
}
.powerby a:hover{
    color: #ffffff;
}