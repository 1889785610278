.addRelationalBackground {
  height: 100vh;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: url("../images/Group2.png") !important;
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}
@media only screen and (max-width: 1100px) {
  .overRideContainer {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
