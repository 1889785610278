.addRelationalBackground {
  height: 100vh;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: url("../images/Group2.png") !important;
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}
.CartWrapper {
  background: transparent;
  position: relative;
}

.allGroundsWrapper {
  display: grid;
  grid-template-columns: repeat(2, auto);
  gap: 25px;
}

.isCartEmptyWrapper {
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.isCartEmptyWrapper article {
  width: 350px !important;
}

.cartColumns {
  position: relative;
  margin-top: 10vh !important;
}

.dateCardWrapper {
  background: transparent;
  box-shadow: none;
}

.cartSummaryWrapper {
  font-family: "Conv_Ailerons";
  background: transparent;
  backdrop-filter: blur(10px);
  box-shadow: 0px 6px 60px #2b2e2a;
  border-radius: 15px;
  height: 80vh;
  position: sticky !important;
  top: 10vh;
}

.cartSummaryInfo {
  margin-top: 50px;
  padding-left: 20px;
  padding-right: 20px;
}

.cartTotalInfo {
  margin-top: 50px;
  background: #719e67;
  padding: 20px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  border-radius: 20px;
}

.checkoutBtn {
  border: none;
  height: 50px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 12px;
  text-align: center;
  margin-left: 16px;
  background-color: #233c28;
  color: #fff;
  box-shadow: 3px 3px 29px #292a2b, 2px 1px 4px #292a2b, 1px 2px 7px #292a2b;
  font-family: Conv_Ailerons;
  margin-top: 50px;
}
.SecondColumns {
  padding: 0 0.75rem !important;
}
.cardheader {
  font-family: Conv_Ailerons;
  background: white;
  border-radius: 10px;
}

@media only screen and (max-width: 1410px) {
  .allGroundsWrapper {
    display: grid;
    grid-template-columns: repeat(1, auto);
  }
}

@media only screen and (max-width: 1100px) {
  .overRideContainer {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

@media only screen and (max-width: 1040px) {
  .cartColumns {
    margin-top: 0 !important;
    display: flex !important;
    flex-direction: column !important;
  }

  .SecondColumns {
    margin-top: 100px;
    width: 100% !important;
  }

  .cartSummaryWrapper {
    height: auto;
    position: relative !important;
  }

  .allGroundsWrapper {
    display: grid;
    grid-template-columns: repeat(2, auto);
    gap: 25px;
  }
}

@media only screen and (max-width: 900px) {
  .allGroundsWrapper {
    display: grid;
    grid-template-columns: repeat(1, auto);
    gap: 15px;
  }
}

@media only screen and (max-width: 490px) {
  .box {
    padding: 0% !important;
  }

  .card-content {
    padding: 0 !important;
  }

  .allGroundsWrapper {
    padding: 0 !important;
  }

  .CartWrapper {
    padding: 3rem 0 !important;
  }

  .cartSummaryWrapper {
    margin: 20px;
  }
}
