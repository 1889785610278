.headerWrapper {
  height: 10vh;
  background-color: #045a14;
}

.btnBackGround {
  background: #045a14 !important;
  border: none;
}
.personCircle {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background: #fff;
}
.headerlogo {
  font-family: HolidayFree;
}
.logorebounce {
  max-height: 4rem !important;
}
@media only screen and (max-width: 1024px) {
  .headerNavLink {
    color: white !important;
  }
}
