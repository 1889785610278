.addRelationalBackground {
  height: 100vh;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: url("../images/Group2.png") !important;
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}
.ProfileWrapper {
  position: relative;
  background: transparent;
}
.ProfileColumns {
  margin-top: 10vh !important;
}
.ProfileLeftColumn {
  background: transparent;
  backdrop-filter: blur(10px);
  box-shadow: 0px 6px 60px #2b2e2a;
  height: 85vh;
  width: 100%;
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.ProfileImageWrapper {
  height: 250px;
  width: 250px;
  border-radius: 50%;
}
.ProfileImageWrapper img {
  height: 100%;
  width: 100%;
  border-radius: 50%;
  object-fit: cover;
}
@media only screen and (max-width: 1100px) {
  .overRideContainer {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

@media only screen and (max-width: 1040px) {
  .cartColumns {
    display: flex !important;
    flex-direction: column !important;
  }
  .SecondColumns {
    width: 100% !important;
  }
  .ProfileLeftColumn {
    height: auto;
    position: relative !important;
  }
  .allGroundsWrapper {
    display: grid;
    grid-template-columns: repeat(2, auto);
    gap: 25px;
  }
}
