.groundWrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-bottom: 0 !important;
}
.GroundLabel {
  font-family: "Conv_Ailerons";
}
.scrollGroundItems {
  min-width: 350px;
  max-height: 500px;
  padding: 20px;
  overflow: auto;
}
.groundItemWrapper {
  height: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 !important;
  border-radius: 15px !important;
}
.deleteWrapper {
  width: 15%;
  height: 100%;
  background-color: #d13232;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
}
.timeDurationWrapper {
  margin-left: 15px;
}
.groundWrapper {
  background: transparent;
  backdrop-filter: blur(10px);
  box-shadow: 0px 6px 60px #2b2e2a;
  border-radius: 15px;
}
@media only screen and (max-width: 400px) {
  .groundWrapper {
    padding: 0 !important;
  }
}
