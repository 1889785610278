.dateCardWrapper {
  background: transparent;
  box-shadow: none;
}
.slotHeaderWrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  z-index: 10;
  background: #437520;
  border-radius: 15px;
}
.addHorizontalBar {
  width: 60%;
  height: 5px;
  background: #507f2e;
}
.slotTitle {
  padding: 20px;
  background: #153311;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;

  font-family: Conv_Ailerons;
}
.slotContentWrapper {
  display: grid;
  max-width: 350px;
  /* grid-template-columns: repeat(3, 1fr); */
}
.cardItemContent,
.slotPriceWrapper {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.slotTimeWrapper {
  width: 70%;
  padding: 1.5rem;
}
.currencyWrapper {
  width: 30%;
  border-left: 2px solid black;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px;
}

.cardItem {
  margin: 15px;
  border-radius: 15px !important;
}

.cardClicked {
  background-color: #000 !important;
  color: white !important;
}
.contentWrapper {
  width: 100%;
  height: 100%;
}
.historygrid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}
.contentWrapper .slotHeaderWrapper {
  width: 100%;
  height: 100px;
  position: sticky;
  top: 0;
}
.cancelModal {
  width: 50%;
}
.cancelModalBody {
  display: flex;
  justify-content: center;
  align-items: center;
}
.customUI {
  text-align: center;
  width: 500px;
  padding: 40px;
  background: #045a14;
  box-shadow: 0 20px 75px rgba(0, 0, 0, 0.23);
  color: #fff;
}

.customUI > h1 {
  margin-top: 0;
}

.customUI > button {
  width: 160px;
  padding: 10px;
  border: 1px solid #fff;
  margin: 10px;
  cursor: pointer;
  background: none;
  color: #fff;
  font-size: 15px;
}
@media only screen and (max-width: 1800px) {
  .historygrid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}
@media only screen and (max-width: 1250px) {
  .historygrid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }
}

@media only screen and (max-width: 1350px) {
  .slotContentWrapper {
    display: grid;
    /* grid-template-columns: repeat(2, 1fr); */
  }
}
@media only screen and (max-width: 1140px) {
  .historygrid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }
}

@media only screen and (max-width: 768px) {
  .historygrid {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
