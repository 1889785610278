.HomeSectionWrapper {
  min-height: 100vh;
  width: 100%;
  height: 100%;
  background: url("../images/Group2.png") !important;
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.HomeTitleWrapper {
  height: auto;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.CarouselWrapper {
  width: 100%;
  height: 40vh !important;
}

.HomeHeader {
  height: 10vh;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.HomeHeader img {
  height: 80px;
}

.buttonWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  cursor: pointer;
}

.button {
  width: 15%;
  background: #fff;
  height: 50px;
  border-radius: 20px;
  color: #000;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .addHomeBackground {
  height: 100vh;
  min-height: 40rem;
  padding-bottom: 0;
} */
.overRideContainer {
  margin: auto !important;
}
.checkoutbtnWrapper {
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.checkoutbtn {
  border: none;
  width: 20%;
  height: 50px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 12px;
  text-align: center;
  margin-left: 16px;
  background-color: white !important;
  color: black !important;
  font-family: Conv_Ailerons;
}
@media only screen and (max-width: 1100px) {
  .checkoutbtn {
    width: 30%;
  }
  .overRideContainer {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
@media only screen and (max-width: 500px) {
  .checkoutbtn {
    width: 40%;
  }
}
@media only screen and (max-width: 425px) {
  .checkoutbtn {
    width: 50%;
  }
}
