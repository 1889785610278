* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}
.input {
  border-radius: 10px !important;
}
@font-face {
  font-family: "Conv_Ailerons";
  src: url("./fonts/Ailerons 400.eot");
  src: local("☺"), url("./fonts/Ailerons 400.woff") format("woff"),
    url("./fonts/Ailerons 400.ttf") format("truetype"),
    url("./fonts/Ailerons 400.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "HolidayFree";
  src: url("./fonts/HolidayFreeRegular.woff2") format("woff2"),
    url("./fonts/HolidayFreeRegular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

h1 h2 h3 h4 h5 h6 {
  font-family: Conv_Ailerons;
}
/* p {
  font-family: Conv_Ubuntu Regular;
} */

.react-multi-carousel-item {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
.react-multi-carousel-item img {
  height: 110px !important;
  width: auto !important;
  opacity: 0.3;
  transition: all 0.5s ease-in-out;
}
.react-multi-carousel-item--active img {
  height: 300px !important;
  width: auto !important;
  opacity: 1;
}

.fab {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #fff;
  position: sticky;
  left: 97%;
  bottom: 0.5%;
  cursor: pointer;
}

@media only screen and (max-width: 1024px) {
  .navbar-menu.is-active {
    background-color: #045a14;
  }
}
