.dateInputStyle {
  font-family: "Conv_Ailerons";
  font-size: 20px;
}
.inputIcons {
  font-size: 20px;
}
.addMinHeight {
  min-height: 100vh !important;
  height: auto !important;
}
.addGroundDivider {
  width: 5px !important;
  background: #fff;
  opacity: 0.3;
  border-radius: 10px;
  margin: 20px !important;
}
.GroundImage {
  height: 250px !important;
  width: 500px !important;
  margin: 5px;
}
.columnsWrapper {
  margin-top: 10vh;
  position: relative;
}
.addGroundBackground {
  margin-top: 20px;
  height: 85vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: transparent;
  backdrop-filter: blur(10px);
  box-shadow: 0px 6px 60px #2b2e2a;
  /* background: #5f8d47; */
  /* background: hsl(140, 70%, 60%); */
  position: sticky;
  top: 10vh;
}
.addBookingBackground {
  position: relative;
  background: transparent !important;
  backdrop-filter: blur(10px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.timeBarWrapper {
  width: 100% !important;
  background: transparent;
  backdrop-filter: blur(10px);
  box-shadow: 0px 6px 25px #2b2e2a;
  /* background-color: #6d9c55; */
  /* background: hsl(140, 70%, 60%); */
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  padding: 10px;
  border-radius: 10px;
}
.slotsWrapper {
  margin-top: 30px;
  width: 100%;
}
.checkoutWrapper {
  background-color: #045a14;
  border-radius: 20px;
  width: 67%;
  z-index: 10;
  -webkit-transform: translateZ(0);

  position: fixed !important;
  top: 98%;
  left: 52%;
  transform: translate(-25%, -100%);
}
.groundMobileWrapper {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.checkoutbtn {
  border: none;
  width: 20%;
  height: 50px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 12px;
  text-align: center;
  margin-left: 16px;
  background-color: #233c28 !important;
  color: #fff !important;
  box-shadow: 3px 3px 29px #292a2b, 2px 1px 4px #292a2b, 1px 2px 7px #292a2b;
  font-family: Conv_Ailerons;
}
.hourcal {
  background: #fff;
  text-align: center;
  width: 90%;
  border-radius: 12px;
  margin-left: 25px;
  height: 50px;
  font-family: Conv_Ailerons;
  margin-top: 11px;
}

@media only screen and (max-width: 1100px) {
  .checkoutbtn {
    width: 30%;
  }
  .timeBarWrapper {
    width: 100%;
    flex-direction: column;
    align-items: center;
  }
  .dateWrapper {
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 768px) {
  .addGroundBackground {
    height: 30vh;
    position: relative;
    flex-direction: row;
    top: 0%;
  }

  .GroundImage {
    height: 110px !important;
    width: 180px !important;
    margin: 0px;
    max-width: none;
  }
  .rotate {
    transform: rotate(-90deg);
    margin-left: -80px;
  }
  .rotate1 {
    transform: rotate(-90deg);
    margin-left: -17px;
  }
  .checkoutWrapper {
    width: 100%;
    left: 25%;
  }
  .checkoutbtn {
    width: 90%;
  }
  .dateInputStyle {
    font-family: "Conv_Ailerons";
    font-size: 15px;
  }
  .inputIcons {
    font-size: 15px;
  }
}
@media only screen and (max-width: 360px) {
  .rotate1 {
    margin-left: -40px;
  }
}
@media only screen and (max-width: 410px) {
  .rotate1 {
    margin-left: -42px;
  }
}
