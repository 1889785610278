.addRelationalBackground {
  height: 100vh;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: url("../images/Group2.png") !important;
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}
.LoginColumns {
  margin-top: 10vh !important;
}
.LoginWrapper {
  position: relative;
  background: transparent;
}
.LoginLeftWrapper {
  margin-top: 20px;
  height: 85vh;
  background: transparent;
  backdrop-filter: blur(10px);
  box-shadow: 0px 6px 60px #2b2e2a;
  position: sticky;
  top: 10vh;
}
.LoginInputs {
  width: 100%;
  height: 50px;
  border-radius: 5px;
  border-color: transparent;
  margin-bottom: 20px;
  color: #fff;
  background-color: #75a269;
  padding: 5px;
  padding-left: 10px;
}
.LoginInputs::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: #fff;
}
.LoginInputs:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #fff;
  opacity: 1;
}
.LoginInputs::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #fff;
  opacity: 1;
}
.LoginInputs:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #fff;
}
.LoginInputs::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #fff;
}

.LoginInputs::placeholder {
  /* Most modern browsers support this now. */
  color: #fff;
}

.signInBtn {
  border: none;
  width: 75%;
  height: 50px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 12px;
  text-align: center;
  margin-left: 16px;
  background-color: white !important;
  color: #000 !important;
  font-family: Conv_Ailerons;
  font-size: 30px;
}
.dividerWrapper {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.dividerWrapper .dividerLine {
  height: 5px;
  width: 30%;
  background: #fff;
  margin: 1px;
}
.dividerWrapper p {
  margin-bottom: 0 !important;
}
.socialIconsWrapper {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
@media only screen and (max-width: 1215px) {
  .LoginColumns {
    display: flex !important;
    flex-direction: column !important;
  }
  .LoginLeftWrapper {
    height: auto;
    position: relative;
  }
  .LoginCartWrapper {
    width: 100% !important;
  }
}
@media only screen and (max-width: 1100px) {
  .overRideContainer {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
@media only screen and (max-width: 490px) {
  .LoginWrapper {
    padding: 3rem 0 !important;
  }
  .LoginLeftWrapper {
    margin: 20px;
  }
  .dividerWrapper .dividerLine {
    width: 25%;
  }
}
