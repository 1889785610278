.addRelationalBackground {
    height: 100vh;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: url("../images/Group2.png") !important;
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
}

.ProfileLeftColumn {
    background: transparent;
    backdrop-filter: blur(10px);
    box-shadow: 0px 6px 60px #2b2e2a;
    width: 90%;
    color: #fff;
    margin-top: 100px !important;
    margin: auto;
    border-radius: 20px;
    padding-bottom: 100px;
    padding: 30px;
}
.pricedate{
    display: flex;
    justify-content: center;
}
.priceday{
    margin-right: 30px;
}
.pricetext{
    margin-top: 20px;
    text-align: center;
}