.slotHeaderWrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  z-index: 10;
  /* background: #437520; */
  border-radius: 15px;
}
.addHorizontalBar {
  width: 70%;
  height: 5px;
  background: #043c04;
}
.disabled {
  background: gray;
  cursor: not-allowed !important;
}
.slotsName {
  font-family: Conv_Ailerons !important;
}
.slotTitle {
  padding: 20px;
  background: #153311;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  font-family: Conv_Ailerons !important;
}
.slotContentWrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}
.cardItemContent,
.slotPriceWrapper {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.slotTimeWrapper {
  width: 70%;
  padding: 1.5rem;
}
.currencyWrapper {
  width: 30%;
  border-left: 2px solid black;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px;
}

.cardItem {
  margin: 15px;
  border-radius: 15px !important;
  max-width: 350px;
}

.cardClicked {
  background-color: #153311 !important;
  color: white !important;
}
.groundName {
  font-size: 25px;
  margin-bottom: 5px;
}
.contentWrapper {
  width: 100%;
  height: 100%;
}

.contentWrapper .slotHeaderWrapper {
  width: 100%;
  height: 100px;
  position: sticky;
  margin-left: 30px;
  top: 10vh;
}
@media only screen and (max-width: 1350px) {
  .slotContentWrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (max-width: 768px) {
  .slotContentWrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}
@media only screen and (max-width: 600px) {
  .slotContentWrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}
@media only screen and (max-width: 450px) {
  .slotContentWrapper {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }
}
